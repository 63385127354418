import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`📫 Contact & Profile`}</h2>
    <p>{`Say hi by sending me an `}<a parentName="p" {...{
        "href": "ekikz1997@gmail.com"
      }}>{`email`}</a>{`, or find me on other
platforms:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linkedin.com/in/rizki-febriansyah97"
        }}>{`LinkedIn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://g.dev/zegveld"
        }}>{`Google Developer Group`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/rizki-febriansyah97"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-LinkedIn-0e76a8?style=flat&labelColor=0e76a8&logo=linkedin&logoColor=white",
          "alt": "Linkedin Badge"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "mailto:ekikz1997@gmail.com"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-Mail-c0392b?style=flat&labelColor=c0392b&logo=gmail&logoColor=white",
          "alt": "Mail Badge"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://hackerrank.com/rizki_f"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/Hacker_Rank-rizki__f-2ea44f?logo=Hackerrank",
          "alt": "Hacker Rank - rizki_f"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://leetcode.com/RFebrians/"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/Leet_Code-RFebrians-ebcc34?logo=Leetcode&logoColor=ebcc34",
          "alt": "Leet Code - RFebrians"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://twitter.com/zegveld_22"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-Twitter-1ca0f1?style=flat&labelColor=1ca0f1&logo=twitter&logoColor=white&link=https://twitter.com/zegveld_22",
          "alt": "Twitter Badge"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      