import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Rizki Febriansyah is an undergraduate student with a strong passion on technologies. `}</p>
    <p>{`He has experience building website , mobile app and machine learning.`}</p>
    <p>{`He learned by variety of methods : from campus , fest , team project , and even a military .`}</p>
    <p>{`Determined to continue moving forward , devoted with some promise to get a brighter tomorrow.`}</p>
    <h5>{`Languages`}</h5>
    <p><img parentName="p" {...{
        "src": "https://github-readme-stats.vercel.app/api/top-langs/?username=RFebrians&layout=compact&bg_color=DEG,0F2027,203A43,2C5364&title_color=E9CEDF&text_color=E9CEDF",
        "alt": "Top Langs"
      }}></img></p>
    <h5>{`Open Source Contribution`}</h5>
    <p><a parentName="p" {...{
        "href": "https://git.io/streak-stats"
      }}><img parentName="a" {...{
          "src": "http://github-readme-streak-stats.herokuapp.com?user=RFebrians&theme=github-dark&date_format=M%20j%5B%2C%20Y%5D&fire=DD00C2",
          "alt": "GitHub Streak"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      