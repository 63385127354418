import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Explore`}</h1>
    <ProjectCard title="Repository" link="https://github.com/RFebrians" bg="linear-gradient(to right, #ad5389 0%, #3c1053 100%)" image="github-readme.jpg" mdxType="ProjectCard">
  Browse Repo on GitHub
    </ProjectCard>
    <ProjectCard title="Works " link="./Works" bg="linear-gradient(to right, #c0392b 0%, #8e44ad 100%)" image="1.jpg" mdxType="ProjectCard">
  Publication Works with Timeline
    </ProjectCard>
    <ProjectCard title="Machine Learning" link="https://kaggle.com/zegveld" bg="linear-gradient(to right, #c0392b 0%, #8e44ad 100%)" image="2.jpg" mdxType="ProjectCard">
  Sort of Notebooks on Kaggle
    </ProjectCard>
    <ProjectCard title="Blog " link="./blog-and-article" bg="linear-gradient(to right, #acb6e5 0%, #86fde8 100%)" image="5.jpg" mdxType="ProjectCard">
  Wiki & Blog
    </ProjectCard>
    <ProjectCard title="Journal" link="./research-playground" bg="linear-gradient(to right, #acb6e5 0%, #86fde8 100%)" image="3.jpg" mdxType="ProjectCard">
  A List of Open Access Journal
    </ProjectCard>
    <ProjectCard title="Another Adventure" link="https://github.com/RFebrians/journey-2" bg="linear-gradient(to right, #334d50 0%, #cbcaa5 100%)" image="4.jpg" mdxType="ProjectCard">
  Go Beyond an Adventure out There!
    </ProjectCard>
    <ProjectCard title="Glossary" link="./glossary" bg="linear-gradient(to right, #334d50 0%, #cbcaa5 100%)" image="6.jpg" mdxType="ProjectCard">
  An Index of terms technology
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      